<template>
  <nav class="sidebar">
    <div class="sidebar-header">
      <a @click.prevent="selectWorkspace"  class="sidebar-brand" style="cursor: pointer;">
        <img src="@/assets/images/wappin-logo-white.png" height="30" />
      </a>
      <div
        class="sidebar-toggler active"
        @click="toggleSidebar"
        ref="sidebarToggler">
        <span></span>
        <span></span>
        <span></span>
      </div>
    </div>
    <div class="sidebar-body" @mouseenter="mouseEnter" @mouseleave="mouseLeave">
      <ul class="nav">
        <SidebarCategory title="Main" />
        <SidebarItem
          v-for="(menuItem, i) in menuList"
          :key="i"
          :data="menuItem"
        />
        <SidebarCategory title="Label" />
        <SidebarItem
          v-for="(label, i) in labelList"
          :key="'label_' + i"
          :data="label"
        />
        <!-- <SidebarItem :data="{ to: '#1', title: 'Lorem', icon: 'tag' }" />
        <SidebarItem :data="{ to: '#2', title: 'Ipsum', icon: 'tag' }" />
        <SidebarItem :data="{ to: '#3', title: 'Dolor', icon: 'tag' }" />
        <SidebarItem :data="{ to: '#4', title: 'Sit Amet', icon: 'tag' }" /> -->
      </ul>
    </div>
  </nav>
</template>

<script>
import PerfectScrollbar from 'perfect-scrollbar';
import cookie from '../../library/cookie';
import labelsAPI from '../../api/labels';
import menuAPI from '../../api/permissionBackoffices';

const menu = (to, title, icon, child = [], el_icon = '', id, pic_only = false, division_admin_only = false) => ({
  to,
  title,
  icon,
  child,
  el_icon,
  pic_only,
  division_admin_only,
  id,
});

export default {
  name: 'Sidebar',
  metaInfo: {
    bodyAttrs: {
      class: ['sidebar-dark'],
    },
  },
  data() {
    return {
      /* eslint-disable no-unneeded-ternary */
      isFolded: false,
      // menuList: [
      //   menu('/dashboard', 'Dashboard', 'activity'),
      //   menu('/notifications', 'Notifications', 'bell', [
      //     menu('/schedule', 'Schedules'),
      //   ], '', true),
      //   menu('/broadcast-templates', 'Broadcast Templates', '', [], 'custom-icon el-icon-notebook-2', true),
      //   menu('/contacts', 'Contacts', 'book', [
      //     menu('/contacts/list', 'List Contacts'),
      //     menu('/contacts/contact-groups', 'Group Contacts'),
      //   ]),
      //   menu('/reports', 'Reports', 'pie-chart', [
      //     menu('/reports/helpdesk', 'Helpdesk'),
      //     menu('/reports/session', 'Channels Session'),
      //     menu('/reports/broadcast', 'Broadcast'),
      //     menu('/reports/download', 'Download'),
      //   ]),
      //   menu('/settings/users', 'Users', 'user', [], '', true),
      //   menu('/settings/divisions', 'Divisions', '', [], 'fa users', true, true),
      //   menu('/settings/agents', 'Agents', '', [], 'fa headset', true),
      //   menu('/settings/channels', 'Channels', 'cast', [], '', true),
      //   menu('/settings/groups', 'Groups', 'users', [], '', true),
      //   menu('/settings/labels', 'Labels', 'tag', [], '', true),
      //   menu('/settings/quick-replies', 'Quick Replies', '', [], 'custom-icon el-icon-chat-line-square', true),
      //   menu('/chatbot-templates', 'Chatbot Template', '', [], 'fa robot', true),
      //   menu('/settings/general', 'Settings', 'settings', '', [], true),
      //   menu('/integrations', 'Integrations', 'git-branch', '', [], true),
      //   menu('/document-tracker', 'Document Tracker', '', [], 'custom-icon el-icon-notebook-2', true),
      // ],
      menuList: [],
      perfectScroll: [],
      labelList: [],
    };
  },
  mounted() {
    if (this.$store.state.auth.userProfile.company_id === '96y5cKiZbwH7myjS_ZEuA') { // digitalent
      this.menuList = this.menuList.filter((v) => !['/contacts'].includes(v.to));
    }
    this.setSidebar();
    if (document.querySelectorAll('.sidebar .sidebar-body').length) {
      this.perfectScroll.push(new PerfectScrollbar('.sidebar-body'));
    }
    if (document.querySelectorAll('.content-nav-wrapper').length) {
      this.perfectScroll.push(new PerfectScrollbar('.content-nav-wrapper'));
    }
    this.getLabelList();
    this.getMenuList();
  },
  computed: {
    isLargeScreen: () => window.document.body.clientWidth > 991,
    activeWorkspace() {
      return this.$store.state.workspace.activeWorkspace;
    },
    activeWorkspaceId() {
      return this.$store.state.workspace.activeWorkspace._id;
    },
    userProfile() {
      return this.$store.state.auth.userProfile || {};
    },
    companyId() {
      return this.$store.state.backOffice.activeCompany;
    },
  },
  watch: {
    isLargeScreen() {
      this.setSidebar();
    },
  },
  methods: {
    mouseEnter() {
      if (document.body.classList.contains('sidebar-folded')) {
        document.body.classList.add('open-sidebar-folded');
      }
    },
    mouseLeave() {
      if (document.body.classList.contains('sidebar-folded')) {
        document.body.classList.remove('open-sidebar-folded');
      }
    },
    setSidebar() {
      if (cookie.get('sidebar-folded') === 'true' && this.isLargeScreen) {
        setTimeout(() => {
          this.toggleSidebar(false);
        }, 200);
      }
    },
    toggleSidebar(needToSetCookie = true) {
      document.body.classList.toggle(this.isLargeScreen ? 'sidebar-folded' : 'sidebar-open');
      this.$refs.sidebarToggler.classList.toggle('not-active');
      this.$refs.sidebarToggler.classList.toggle('active');
      this.isFolded = !this.isFolded;
      if (needToSetCookie && this.isLargeScreen) cookie.set('sidebar-folded', this.isFolded);
    },
    selectWorkspace() {
      this.$store.dispatch('workspace/clearActiveWorkspace')
        .then(() => {
          this.$router.push({ name: 'Workspaces', query: { cid: this.$store.state.company.activeCompany.id } });
        });
    },
    async getLabelList() {
      await labelsAPI.getList(this.activeWorkspace._id, { company_id: this.companyId })
        .then(async (res) => {
          this.labelList = res.data.rows.map((v) => {
            const item = v;
            item.to = `/conversations/labels/${item.id}`;
            item.icon = 'tag';
            return item;
          }).filter((v) => v.show_in_sidebar);
        })
        .catch(() => {});
    },
    async getMenuList() {
      await menuAPI.getList(true)
        .then(async (res) => {
          this.menuList = res.data.map((v) => {
            let child = [];
            if (v.child) {
              child = v.child.map((v1) => {
                const childMenu = (menu(v1.url_path, v1.name, '', [], '', v1.id));
                return childMenu;
              });
            }
            const item = menu(v.url_path, v.name, v.icon, child, v.custom_icon, v.id, true);
            return item;
          });
        })
        .catch(() => {});
    },
  },
  components: {
    SidebarCategory: () => import('./SidebarCategory.vue'),
    SidebarItem: () => import('./SidebarItem.vue'),
  },
};
</script>
