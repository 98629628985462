import api from '../library/axios';

export default {
  getList(workspace_id, query = {}) {
    return api({
      method: 'get',
      url: `/api/labels/${workspace_id}`,
      params: query,
    });
  },
  create({
    title,
    description,
    color,
    workspace_id,
    show_in_sidebar = false,
    company_id,
  }) {
    return api({
      method: 'post',
      url: `/api/labels/${workspace_id}`,
      data: {
        title,
        description,
        color,
        show_in_sidebar,
        company_id,
      },
    });
  },
  update({
    title,
    workspace_id,
    description,
    color,
    id,
    show_in_sidebar = false,
  }) {
    return api({
      method: 'patch',
      url: `/api/labels/${workspace_id}/${id}`,
      data: {
        title,
        description,
        color,
        show_in_sidebar: show_in_sidebar.toString(),
      },
    });
  },
  delete_label({
    workspace_id,
    id,
  }) {
    return api({
      method: 'delete',
      url: `/api/labels/${workspace_id}/${id}`,
    });
  },
};
